'use client'

import { useMemo } from 'react'

import { useMediaQuery } from './useMediaQuery'

const useResponsive = (width: string | number = 1024) => {
	const isTouch = useMediaQuery(width)
	return useMemo(() => ({ isTouch, isDesktop: !isTouch }), [isTouch])
}

export { useResponsive }
