'use client'

import { Children } from 'react'
import { useMemo, useState } from 'react'

import { ArrowIcon } from '@sapphireui/core/icons'
import { clsx, type XOR, type ReactChildren } from '@sapphireui/core/utils'
import { Text, Collapse, type CollapseProps, ChipProps, Chip } from '@sapphireui/react'

type WithChildren = {
	children: ReactChildren
}

type WithoutChildren<T> = {
	items?: T[]
	children: (value: T, i: number) => JSX.Element
}

type ChildrenProps<T> = XOR<WithoutChildren<T>, WithChildren>

type FolderProps<T> = Pick<
	CollapseProps,
	'size' | 'title' | 'style' | 'className' | 'initialOpen' | 'variant' | 'disabled'
> &
	ChildrenProps<T> & {
		withCounter?: boolean
	}

const Folder = <T,>({
	size,
	items,
	title,
	style,
	children,
	className,
	variant = 'light',
	initialOpen = true,
	withCounter = true,
}: FolderProps<T>) => {
	const [open, setOpen] = useState<boolean>(initialOpen)

	const total = items ? items?.length : Children.count(children)
	const disabled = !total

	const isOpen = disabled ? false : open

	const contentProps = useMemo(() => {
		const arrowDir = isOpen ? 'top' : 'right'

		return {
			contentLeft: (
				<ArrowIcon
					dir={arrowDir}
					strokeWidth={5}
				/>
			),
		}
	}, [isOpen])

	return (
		<Collapse
			{...contentProps}
			size={size}
			variant={variant}
			disabled={disabled}
			initialOpen={isOpen}
			onChange={setOpen}
			title={
				<span className="flex flex-row items-center gap-x-3">
					<Text>{title}</Text>
					{!!total && withCounter ? (
						<FolderTotal
							size={size}
							value={total}
						/>
					) : null}
				</span>
			}
		>
			<div
				style={style}
				className={className}
			>
				{typeof children === 'function' ? items?.map(children) : children}
			</div>
		</Collapse>
	)
}
type FolderTotalProps = Omit<ChipProps, 'value'> & {
	value?: number
}

const FolderTotal = ({ value, className, ...props }: FolderTotalProps) => (
	<Chip
		{...props}
		value={value || 0}
		color={value ? 'info' : 'default'}
		variant={value ? 'shadow' : 'solid'}
		className={clsx('rounded-sm px-2 font-semibold leading-none cursor-[inherit]', className)}
	/>
)

export { Folder }
export type { FolderProps }
