'use client'

import { memo } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Dialog, type DialogProps } from '@sapphireui/react'

import { useResponsive } from '../hooks/useResponsive'

const AdaptiveDialog = memo(({ position, className, ...props }: DialogProps) => {
	const { isTouch } = useResponsive(639)

	return (
		<Dialog
			{...props}
			position={isTouch ? 'bottom' : position}
			className={clsx(
				'py-6 !px-8 max-h-screen border-border border-1.5 bg-[rgb(9,9,12)] !rounded-[1.25em]',
				isTouch ? 'w-full h-full' : '',
				className,
			)}
		/>
	)
})

export { AdaptiveDialog as Dialog }
export {
	DialogBody,
	DialogTitle,
	DialogFooter,
	DialogHeader,
	DialogCloseButton,
	DialogDescription,
} from '@sapphireui/react'
export type { DialogProps } from '@sapphireui/react'
