import { Fragment } from 'react'
import { tv, type VariantProps } from 'tailwind-variants'

import type { As, StyleProps, ReactChildren } from '@sapphireui/core/utils'

type ResponsiveProps = StyleProps &
	ResponsiveVariants & {
		as?: As
		mobile: ReactChildren
		desktop: ReactChildren
	}

const Responsive = ({
	as,
	mobile,
	desktop,
	className,
	breakpoint = 'md',
	...props
}: ResponsiveProps) => {
	const Slot = as || 'div'

	const { mobile: mobileStyles, desktop: desktopStyles } = responsiveVariants({
		breakpoint,
	})

	return (
		<Fragment>
			<Slot
				{...props}
				className={desktopStyles({ className })}
			>
				{desktop}
			</Slot>
			<Slot
				{...props}
				className={mobileStyles({ className })}
			>
				{mobile}
			</Slot>
		</Fragment>
	)
}

const responsiveVariants = tv({
	slots: {
		desktop: '',
		mobile: '',
	},
	variants: {
		breakpoint: {
			sm: {
				desktop: 'max-sm:hidden',
				mobile: 'sm:hidden',
			},
			md: {
				desktop: 'max-md:hidden',
				mobile: 'md:hidden',
			},
			lg: {
				desktop: 'max-lg:hidden',
				mobile: 'lg:hidden',
			},
		},
	},
	defaultVariants: {
		breakpoint: 'md',
	},
})

type Breakpoint = Exclude<ResponsiveVariants['breakpoint'], undefined>

type ResponsiveVariants = VariantProps<typeof responsiveVariants>

Responsive.displayName = 'Responsive'

export { Responsive }
export type { Breakpoint, ResponsiveProps }
