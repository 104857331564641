import type { Config } from 'tailwindcss'

import font from './src/font'
import border from './src/border'
import colors from './src/colors'
import animation from './src/animation'

import plugin from './src/plugin'

export default {
	darkMode: ['class'],
	content: [],
	theme: {
		...colors,
		...border,
		...font,
		...animation,
		borderRadius: {
			xs: '0.25em',
			sm: '0.5em',
			md: '0.625em',
			lg: '0.75em',
			xl: '1em',
			'2xl': '1.25em',
			'3xl': '1.5em',
			full: '999px',
		},
	},
	plugins: [plugin()],
} satisfies Config
